:root{
  --primaryColor: hsl(199, 100%, 33%);
  --secondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201,33%,16%);
  --textColor: hsl(240,4%,36%);
  --whiteColorDeam: hsl(0,0%,93%);
  --greyText: rgb(190,190,190);
  --inputColor: rgb(239,239,239);
  --bodyColor: rgb(240,240,246);
  --cardBG: rgb(225,225,235);
}

.header{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--whiteColorDeam);
    width: 100%;
    padding: 1rem;
    z-index: 1000;
     box-shadow: 0 2px 4px rgba(2, 15, 29, 0.242);
     font-size: 2rem;
     top: 0;
     height: 10%;


.logo{
    color: var(--blackColor);
    font-weight: 600;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    display: flex;
    transform: translateY(-20px);

    .icon-size{
        image-rendering: optimizeQuality;
        width: 76px;       
        color: var(--primaryColor);
        transform: translateY(31px) 
    }
    .logoText{
        font-size: 1.2rem;
        color: var(--blackColor);
        transform: translateY(20px);
    }
    .spanLogo{
        font-size: 1.2rem;
        color: var(--primaryColor);
        display: inline-block;
        
    }
    .spanLogo1 {
    display: block; /* Mengubah tampilan spanLogo1 menjadi blok */
    font-size: 1rem;
    color: var(--primaryColor);
    margin-left: 5rem;
    margin-top: -.5rem;
  }
}

.logo.spanIcon{
    color: var(--greyText);
}

@media screen and (max-width: 900px) {
    .logo{
    color: var(--blackColor);
    font-weight: 600;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    display: flex;

    .icon-size{
        image-rendering: optimizeQuality;
        width: 70px;       
        color: var(--primaryColor);
        transform: translateY(30px);    
    }
    .logoText{
        font-size: 1rem;
        color: var(--blackColor);
    }
    .spanLogo{
        font-size: 1rem;
        color: var(--primaryColor);
    }
    .spanLogo1 {
    display: block; /* Mengubah tampilan spanLogo1 menjadi blok */
    font-size: .7rem;
    color: var(--primaryColor);
    margin-left: 5rem;
  }
}
    .navBar{
        position: absolute;
        background: var(--whiteColor);
        height: max-content;
        width: 80%;
        border-radius: 1rem;
        top: -500rem;
        left: 50%;
        padding: 1rem;
        transform: translate(-50%);
        z-index: 2000;
        box-shadow: 0 2px 4px rgba($color: #000000, $alpha: 1.0);
        transition: 1s ease-in-out;


        .navLists{
           flex-direction: column;
           justify-content: center;
           width: 100%;
           margin: auto;
           padding: .5rem 0;

           .navItem{
                padding: .5rem 0;

                .navLink{
                    color: var(--textColor);
                    font-size: 1.3rem;
                    font-weight: 600;  
                    &:hover{
                        color: var(--primaryColor);
                    }                  
                }
           }
        

           .btn{
                margin-top: 1rem;

                a{
                    font-weight: 600;
                    color: var(--whiteColor);
                }
           }
        }
        .closeNavbar{
            position: absolute;
            top: 1rem;
            right: 1.5rem;
            color: var(--primaryColor);
            &:hover{
                color: var(--secondaryColor);
            }
        }
    }
        .activeNavbar{
            top: 7rem;
        }
  }

  .toggleNavbar{
    .icon{
        font-size: 25px;
        color: var(--primaryColor);
    }
    &:hover.icon{
        color: var(--secondaryColor);
    }
  }
    
}
    
// Media Queries
@media screen and (min-width:900px) {
    .toggleNavbar,.closeNavbar{
        display: none;
    }

    .header{
        padding: 1.5rem 2rem;

        .navBar{
         .navLists{
           display: flex;
           align-items: center;
           justify-content: center;
            flex-direction: row;

            .navItem{
                .navLink{
                    color: var(--textColor);
                    padding: 0 0.45rem;
                    font-size: 1.2rem;
                    font-weight: 500;

                    &:hover{
                        color: var(--primaryColor);
                    }    
                }
            }

            .btn{
                margin-left: 1rem;
                a{
                    color: var(--whiteColor);
                    font-weight: 500;
                }
            }
         }
       }
    }
}

@media screen and (min-width:900px){
    .header{
        .navBar{
            .navLists{
                .navItem{
                    padding: 0 0.50rem;
                }
            }
        }
    }
}
@media screen and (min-width:1240px){
    .header{
        padding: 1rem 8rem;
    }
}

