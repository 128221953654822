:root {
  --primaryColor: hsl(199, 100%, 33%);
  --secondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(
    to right,
    hsl(187, 85%, 43%),
    hsl(199, 100%, 33%)
  );
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 240, 246);
  --cardBG: rgb(225, 225, 235);
  --cardHover: rgb(255, 255, 255);
}

.main {
  width: 100%;
}
.main .secTitle .title {
  position: relative;
  width: -moz-max-content;
  width: max-content;
  color: var(--textColor);
  margin: 1rem 0;
  z-index: 2;
  font-size: 37px;
}
.main .secTitle .title::after {
  position: absolute;
  content: "";
  background: var(--secondaryColor);
  height: 5px;
  width: 120px;
  right: 0;
  bottom: -2px;
  z-index: -1;
}
.main .setContent {
  justify-content: center;
  gap: 1.5rem;
}
.main .setContent .singleDestination {
  height: 100%;
  display: grid;
  row-gap: 10px;
  padding: 1rem;
  border-radius: 10px;
  align-items: center;
  background: var(--cardBG);
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
  overflow: hidden;
}
.main .setContent .singleDestination:hover {
  background: var(--cardHover);
  box-shadow: 1 4px 4px rgba(85, 85, 114, 0.549);
  transition: 0.3s ease;
}
.main .setContent .singleDestination .imageDiv {
  height: 400px;
  width: 100%;
  overflow: hidden;
}
.main .setContent .singleDestination .imageDiv img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: 2s ease;
}
.main .setContent .singleDestination .imageDiv:hover img {
  transform: scale(1.1);
}
.main .setContent .singleDestination .cardInfo {
  padding: 1rem;
}
.main .setContent .singleDestination .cardInfo .destTitle {
  color: var(--blackColor);
  font-size: 1.25rem;
}
.main .setContent .singleDestination .cardInfo .continent {
  gap: 0.5rem;
}
.main .setContent .singleDestination .cardInfo .continent .icon {
  color: var(--textColor);
  font-size: 25px;
}
.main .setContent .singleDestination .cardInfo .continent .name {
  color: var(--textColor);
  font-weight: 400;
  font-size: 20px;
}
.main .setContent .singleDestination .cardInfo .fees {
  width: 100%;
  justify-content: space-between;
  padding: 1rem;
  margin: 1rem 0;
  border-top: 1.5px solid var(--greyText);
  border-bottom: 1.5px solid var(--greyText);
}
.main .setContent .singleDestination .cardInfo .fees .grade {
  color: var(--textColor);
  max-width: 100px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main .setContent .singleDestination .cardInfo .fees .grade small {
  text-align: center;
  background: var(--gradientColor);
  border-radius: 1rem;
  color: var(--whiteColor);
  padding: 1px 10px;
  font-size: 10px;
  margin-left: 5px;
}
.main .setContent .singleDestination .cardInfo .fees .price {
  font-size: 1.3rem;
  color: var(--blackColor);
}
.main .setContent .singleDestination .cardInfo .des {
  font-size: 13.5px;
  color: var(--textColor);
  font-weight: 500;
}
.main .setContent .singleDestination .cardInfo .btn {
  margin-top: 1rem;
  color: var(--whiteColor);
  font-weight: 600;
  justify-content: space-between;
  gap: 0.5rem;
}
.main .setContent .singleDestination .cardInfo .btn .icon {
  align-self: center;
  font-size: 18px;
}

@media screen and (min-width: 500px) {
  .title {
    font-size: 1.5rem;
  }
  .title::after {
    width: 155px !important;
  }
  .setContent {
    grid-template-columns: repeat(2, 1fr);
  }
  .setContent .singleDestination {
    height: auto;
  }
}
@media screen and (min-width: 840px) {
  .setContent {
    grid-template-columns: repeat(3, 1fr);
  }
  .setContent .singleDestination {
    height: auto;
  }
}/*# sourceMappingURL=main.css.map */