@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap');

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

/* Color and Styling */
:root{
  --primaryColor: hsl(199, 100%, 33%);
  --secondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201,33%,16%);
  --textColor: hsl(240,4%,36%);
  --whiteColorDeam: hsl(0,0%,93%);
  --greyText: rgb(190,190,190);
  --inputColor: rgb(239,239,239);
  --bodyColor: rgb(240,240,246);
  --cardBG: rgb(225,225,235);
}

html{
  scroll-behavior: smooth;
  font-family: 'Poppins';
}

a{
  text-decoration: none;
}

li{
  list-style: none;
}

.section{
  padding: 4rem 0 2 rem;
}

.container{
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.icon{
  cursor: pointer;
  font-size: 2rem;
}

.flex{
  display: flex;
  align-items: center;
}

.grid{
  display: grid;
  align-items: center;
}

.btn{
  padding: .6rem 1.5rem;
  background: var(--gradientColor);
  border-radius: 3rem;
  border: none;
  outline: none;
  cursor: pointer; 
}

.btn a{
  color: var(--whiteColor);
  font-weight: 500;
}

.btn:hover{
  background: var(--secondaryColor)
}

img,video{
  width: 100%;
  height: auto;
}

input{
  font-size: 100%;
}

body{
  background: var(--bodyColor);
}


/* Media Queries */

@media screen and (min-width: 1240px) {
  .container{
    width: 80% !important;
    margin: auto !important;
  }

  .section{
    padding: 6rem 0 4rem;
  }
} 

@media screen and (min-width: 2260px){
  body{
    zoom:1.7;
  }
}