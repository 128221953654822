:root {
  --primaryColor: hsl(199, 100%, 33%);
  --secondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 240, 246);
  --cardBG: rgb(225, 225, 235);
  --cardHover: rgb(255, 255, 255);
}

.ContactInfo {
  padding: 1rem;
  background-color: var(--cardBG);
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 1.3rem;
  margin-left: 1rem; /* Tambahkan tanda semicolon (;) di sini */
  font-size: 1.2rem;

  .item {
    color: var(--blackColor);
    display: flex;
    justify-content: flex-start; /* Menggunakan "justify-content" untuk menggeser konten ke kiri */
    align-items: center;
    gap: 1rem; /* Menambahkan jarak antara ikon dan teks */
  }

  svg {
    width: 3.5rem;
  }

  .info {
    color: var(--textColor);
    font-size: 1rem; /* Mengurangi ukuran font untuk teks info */
  }
}
