:root{
  --primaryColor: hsl(199, 100%, 33%);
  --secondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201,33%,16%);
  --textColor: hsl(240,4%,36%);
  --whiteColorDeam: hsl(0,0%,93%);
  --greyText: rgb(190,190,190);
  --inputColor: rgb(239,239,239);
  --bodyColor: rgb(240,240,246);
  --cardBG: rgb(225,225,235);
}

.footer{
    width: 100%;
    position: relative;
    padding: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    .videDiv{
        position: absolute;
        height: 100%;
        width: 100%;

        video{
            margin-top: 10px;
            height: 100%;
            object-fit: cover;
        }

        &::after{
            content: '';
            position: absolute;
            background: rgba(10, 84, 107, 0.307);
            top: 10px;
            bottom: 0;
            right: 0;
            left: 0;
            mix-blend-mode: multiply;
        }
    }

    .secContent{
        width: 100%;
        height: max-content;
        padding: 2rem initial;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: auto;
        row-gap: 2rem;
        z-index: 100;

        .contactDiv{
            justify-content: space-between;
            flex-direction: column;
            gap: 1rem;

            .text{
                small{
                    font-size: 13px;
                    font-weight: 400;
                    color: var(--whiteColor);
                }

                h2{
                    color: var(--whiteColor);
                    font-size: 1.8rem;
                    
                }
            }
        }

        .footerCard{
            position: relative;
            padding: 1rem 1rem 4rem;
            gap: 2rem;
            border-radius: 1rem;
            background: var(--bodyColor);
            width: 100%;
             justify-content: center;
             flex-direction: column;
             align-items: flex-start;
             overflow: hidden;

             .footerIntro{
                flex-basis: 50%;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                row-gap: .5rem;

                .logoDiv{
                    .logo{
                        color: var(--blackColor);
                        font-weight: 600;
                        
                        .icon-size{
                            image-rendering: optimizeQuality;
                            width: 80px;
                            color: var(--primaryColor);
                            margin-right: 10px;
                        }
                        .logoText{
                            color: var(--blackColor);
                        }
                        .spanLogo{
                            color: var(--primaryColor);;
                        }
                    }
                }

                .footerParagraph{
                    font-size: 15px;
                    color: var(--textColor);
                }

                .footerSocials{
                    column-gap: 10px;

                    .icon{
                        color: var(--blackColor);
                        font-size: 20px;

                        &:hover{
                            color: var(--primaryColor);
                        }
                    }
                }
             }

             .footerLinks{
                flex-basis: 50%;
                width: 100%;
                gap: 1rem;
                grid-template-columns: repeat(2,1fr);
                justify-content: space-between;

                .linkGroup{
                    .groupTitle{
                        display: block;
                        font-weight: 600;
                        color: var(--blackColor);
                        margin-bottom: .5rem;
                    }

                    .footerList{
                        font-size: 15px;
                        color: var(--textColor);
                        transition: .3s ease-in-out;

                        .icon{
                            font-size: 15px;
                            color: var(--greyText);
                        }

                        &:hover{
                            color: var(--primaryColor);
                            transform: translateX(7px);

                            .icon{
                                color: var(--secondaryColor);
                            }
                        }
                    }
                }
                
             }

             .footerDiv{
                flex-direction: column;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: var(--secondaryColor);
                padding: .5rem 2rem;

                small{
                    font-size: 10px;
                    color: var(--whiteColor);
                }
             }
        }
    }
}

// Media Queries

@media screen and (min-width: 500px) {
    .footer{
        .secContent{
            .footerCard{
                .footerLinks{
                    grid-template-columns: repeat(3,1fr);
                    padding-bottom: 1rem;
                }

                .footerDiv{
                    justify-content: space-between;
                    flex-direction: row;

                    small{
                        font-size: 14px;
                        color: var(--whiteColor);
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 840px) {
    .footer{
        padding: 5rem 0;
        .secContent{
            .footerCard{
                flex-direction: row;

                .footerLinks{
                    grid-template-columns: repeat(3,1fr);
                    padding-bottom: 1rem;

                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .footer{
       .secContent{
        .footerCard{
            padding: 3rem 1rem;

            .footerIntro{
                .footerParagraph{
                    font-size: 15px;
                    color: var(--textColor);
                }

                .footerSocials{
                    column-gap: .5rem;
                    .icon{
                        color: var(--blackColor);
                        font-size: 25px;
                        &:hover{
                            color: var(--primaryColor);
                        }
                    }
                }
            }

            .footerLinks{
                .linkGroup{
                    .footerList{
                        font-size: 16px;
                        padding: 5px 0;
                    }
                }
            }
         }
       }
    }
}


@media screen and (min-width: 660px) {
    .footer{
        .secContent{
            .contactDiv{
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }
}