:root {
  --primaryColor: hsl(199, 100%, 33%);
  --secondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201,33%,16%);
  --textColor: hsl(240,4%,36%);
  --whiteColorDeam: hsl(0,0%,93%);
  --greyText: rgb(190,190,190);
  --inputColor: rgb(239,239,239);
  --bodyColor: rgb(240,240,246);
  --cardBG: rgb(225,225,235);
}

.home {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.home .overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgb(47, 106, 107);
  top: 0;
  bottom: 0;
  z-index: 1;
  mix-blend-mode: hard-light;
}
.home video {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  -o-object-fit: cover;
     object-fit: cover;
}
.home .homeContent {
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  padding: 8rem 1.5rem 3rem;
  display: flex;
  row-gap: 3rem;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  margin: auto;
  color: var(--whiteColor);
  z-index: 100;
}
.home .homeContent .textDiv {
  padding: 2rem 0 2rem 2rem;
}
.home .homeContent .textDiv .smallText {
  font-size: 13px;
  text-transform: uppercase;
}
.home .homeContent .textDiv .homeTitle {
  position: relative;
  font-size: 1.8rem;
}
.home .homeContent .homeFooterIcons {
  width: 100%;
  justify-content: space-between;
}
.home .homeContent .homeFooterIcons .icon {
  font-size: 20px;
  margin: 0 5px;
  color: var(--whiteColor);
  font-weight: 600;
}
.home .homeContent .homeFooterIcons .icon:hover {
  color: var(--primaryColor);
}

@media screen and (min-width: 500px) {
  .home .homeContent .textDiv {
    font-size: 16px;
  }
  .home .homeContent .homeTitle {
    position: relative;
    font-size: 2.3rem;
    width: -moz-max-content;
    width: max-content;
  }
  .home .homeContent .homeTitle::after {
    position: absolute;
    content: "";
    height: 5px;
    width: 145px;
    right: 0px;
    bottom: 5px;
    z-index: -1;
  }
  .home .cardDiv {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 660px) {
  .home .homeContent .cardDiv {
    padding: 2rem 1rem;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 940px) {
  .home {
    height: 30vh;
  }
}
@media screen and (min-width: 1240px) {
  .home {
    height: 50vh;
  }
  .home .homeContent {
    padding-left: 0;
    padding-right: 0;
  }
  .home .cardDiv {
    padding: 2rem 1rem;
    grid-template-columns: repeat(3, 1fr);
  }
}/*# sourceMappingURL=home.css.map */