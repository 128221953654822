:root {
  --primaryColor: hsl(199, 100%, 33%);
  --secondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201,33%,16%);
  --textColor: hsl(240,4%,36%);
  --whiteColorDeam: hsl(0,0%,93%);
  --greyText: rgb(190,190,190);
  --inputColor: rgb(239,239,239);
  --bodyColor: rgb(240,240,246);
  --cardBG: rgb(225,225,235);
  --cardHover: rgb(255,255,255);
}

.contact {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.secTitle {
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 70px;
  font-size: 3rem;
}

.contactSection__wrapper {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  position: relative; /* Untuk elemen garis */
}

.left {
  flex: 1;
  padding-right: 20px;
  order: 1;
  width: 50%; /* Ukuran class left sama dengan class right */
}

.right {
  width: 50%; /* Ukuran class right sama dengan class left */
  order: 3;
}

@media screen and (max-width: 890px) {
  .contactSection__wrapper {
    flex-direction: column; /* Mengubah tata letak menjadi tumpukan vertikal */
    align-items: center; /* Mengatur konten agar berada di tengah-tengah */
  }
  .left,
  .right {
    width: 100%; /* Mengisi lebar class left dan class right menjadi 100% */
    order: 0; /* Mengubah order menjadi 0 agar class left dan right tetap dalam urutan aslinya */
  }
}/*# sourceMappingURL=contactSection.css.map */